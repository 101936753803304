import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";
import "./Archives.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function ArchivesPage() {
  const archiveData = [
    {
      id: 109,
      imageName: "journal2-issue4.png",
      volumeNumber: "Vol.02, Issue. 04, July(2024)",
    },
    {
      id: 108,
      imageName: "journal2-issue3.png",
      volumeNumber: "Vol.02, Issue. 03, May(2024)",
    },
    {
      id: 107,
      imageName: "journal2-issue2.png",
      volumeNumber: "Vol.02, Issue. 02, Mar(2024)",
    },
    {
      id: 106,
      imageName: "journal2-issue1.png",
      volumeNumber: "Vol.02, Issue 01, Jan(2024)",
    },
    {
      id: 105,
      imageName: "journal-cover-issue5.png",
      volumeNumber: "Vol. 01, Issue. 05, Nov (2023)",
    },
    {
      id: 104,
      imageName: "journal-cover-issue4.png",
      volumeNumber: "Vol. 01, Issue. 04, Sep (2023)",
    },
    {
      id: 103,
      imageName: "journal-cover-issue3.png",
      volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01, Issue. 01, Mar (2023)",
    },
  ];
  return (
    <div>
      <Header />
      <Container>
        <Row className="pb-2">
          <h3 className="section-heading">Archives</h3>
        </Row>
        {archiveData.map((data) => (
          <Row className="pb-5">
            <Col lg={3}>
              <Link to={`/archived-issues/${data.id}`}>
                <img
                  src={`./images/${data.imageName}`}
                  alt="Journal Thumbnail"
                  className="journal-thumbnail"
                  style={{ width: "15rem" }}
                />
              </Link>
            </Col>
            <Col style={{display: "flex", alignItems: "center"}}>
              <Link
                to={`/archived-issues/${data.id}`}
                style={{ textDecoration: "none", color: "#3423a6" }}
              >
                <p style={{ fontWeight: "bold", padding: "0.5rem 0" }}>
                  {data.volumeNumber}
                </p>
              </Link>
            </Col>
          </Row>
        ))}
      </Container>
      <Footer />
    </div>
  );
}

export default ArchivesPage;
