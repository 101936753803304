import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";
import "./CurrentIssues.css";
import { Link } from "react-router-dom";
import Papers from "../../BlockComponents/Papers/Papers";

function CurrentIssues() {
  return (
    <div>
      <Header />
      <Container className='pt-2'>
        <Row className='pb-3'>
          <h4 className='section-heading'>Vol 02. Issue. 04 Jul (2024)</h4>
        </Row>
        <Row className='flex-style'>
          <Col lg={3}>
            <Link to='/'>
              <img
                src='./images/journal2-issue3.png'
                alt='Journal Thumbnail'
                className='journal-thumbnail'
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col>
            <p>
              <span style={{ fontWeight: "bold" }}>PUBLISHED:</span> 2024-07-20
            </p>
          </Col>
        </Row>
      </Container>
      <Papers />
      <Footer />
    </div>
  );
}

export default CurrentIssues;
